import * as React from "react";

import Layout from "../../components/Layout";
import BlogRoll1 from "../../components/BlogRoll1";

export default class ProjectsIndexPage extends React.Component {
  render() {
    return (
      <Layout>
        <section className="section has-background-primary">
          <div className="container">
            <div className="content">
              <h1 className="title is-size-2 has-text-weight-bold is-bold-light line-after">
                <span>Projects</span>
              </h1>
              <BlogRoll1/>
            </div>
          </div>
        </section>
      </Layout>
    );
  }
}
